

import Login from './components/Login';
import Home from './components/Home';
import React from 'react';
import {BrowserRouter,Navigate,Route,Routes} from "react-router-dom";
import ProtectedRoute from './utils/ProtectedRoute';
import PrivacyPolicy from './components/PrivacyPolicy';


function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/home" element={
        <ProtectedRoute>
        <Home/>
        </ProtectedRoute>
     }/>
        <Route path="/login" element={<Login/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        {/* <Route path="*" element={<Navigate to="/login" />} /> */}
    </Routes>
    </BrowserRouter>
  );
}

export default App;