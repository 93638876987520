import React, { useEffect, useState } from "react";
import Navbar from "./Navbaar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import axios from "axios";
import { AES, enc } from "crypto-js";
import CryptoJS from "crypto-js";

const Home = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [secretCode, setsecretCode] = useState("");
  const [loading, setLoading] = useState(false); // State to control loading state
  useEffect(() => {
    // Retrieve encrypted userId from local storage
    const encryptedId = localStorage.getItem("userId");

    // Decrypt userId
    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedId,
      process.env.REACT_APP_LOGIN_K
    );
    const decryptedUserId = decryptedBytes.toString(CryptoJS.enc.Utf8);

    setUserId(decryptedUserId); // Set decrypted userId to state
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const token = localStorage.getItem("token"); // Assuming the token is stored in local storage
      const response = await axios.post(
        "https://backend.mylastwords.cloud/api/v1/user/verify-secret-code",
        { userID: userId, secretCode },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Set the Authorization header with the bearer token
          },
        }
      );
      if (response.data.success) {
        console.log(response?.data);
        Swal.fire({
          title: "Success",
          text: `${response.data.message}`,
          icon: "success",
          showConfirmButton: false, // Hide the OK button
          timer: 2000, // Automatically close after 2 seconds
        });
      
        setsecretCode("")
      } else {
        // Invalid email or secretCode
        Swal.fire("Error", "Invalid secretCode", "error");
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: `${error?.response?.data?.message}`,
        icon: "error",
        showConfirmButton: false, // Hide the OK button
        timer: 2000, // Automatically close after 2 seconds
      });
     
    } finally {
      setLoading(false); // Reset loading state after API response
    }
  };
  return (
    <>
      <Navbar />
      <div className="home-container">
        <div className="verify-box">
          <h3>Verify Well-Being</h3>
          <p>
            Verify well-being by entering your secret code. Your security
            matters to us.
          </p>

          <form onSubmit={handleSubmit} className="form-secret">
            <h6>Secret Code</h6>

            <input
              type="password"
              placeholder="Enter your secretCode"
              className="input-secret mb-4"
              value={secretCode}
              onChange={(e) => setsecretCode(e.target.value)}
              required
            />

            <button className="verify-btn" disabled={loading}>
              {loading ? <div className="loading-spinner" /> : "Verify Now"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Home;
