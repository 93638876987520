import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    const location = useLocation();
    
    // Check if token is not present, null, or undefined
    if (token === null && token === undefined ) {
        // If token is not present, redirect to login page
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // If token is present, allow access to the protected route
    return children;
};

export default ProtectedRoute;
