import React from "react";
import Logo from "../images/Logo_IFIDIE.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear token or perform logout action
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    // Redirect to login page or any other page
    navigate("/login");
  };

  return (
    <nav className="navbar" style={{ backgroundColor: "black", color: "white", padding: "10px" }}>
      <div className="nav-logo" style={{ marginRight: "20px" }}>
        <img src={Logo} alt="Logo"  />
        {/* <h3>My Last Words</h3> */}
      </div>
      <div className="logout-button">
        <button className="logout-hover" onClick={handleLogout} style={{ backgroundColor: "transparent", border: "none", color: "white", cursor: "pointer" }}>Logout</button>
      </div>
    </nav>
  );
};

export default Navbar;
