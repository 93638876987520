import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      This Privacy Policy describes Our policies and procedures on the
      collection, use and disclosure of Your information when You use the
      Service and tells You about Your privacy rights and how the law protects
      You.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      We use Your Personal data to provide and improve the Service. By using the
      Service, You agree to the collection and use of information in accordance
      with this Privacy Policy.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Interpretation and Definitions
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Interpretation
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The words of which the initial letter is capitalized have meanings defined
      under the following conditions. The following definitions shall have the
      same meaning regardless of whether they appear in singular or in plural.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>Definitions</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.25in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      For the purposes of this Privacy Policy:
    </span>
  </p>
  <ul
    type="disc"
    style={{
      marginBottom: "0in",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "start",
      textIndent: 0,
      textDecoration: "none",
      marginTop: "0in",
    }}
  >
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Account</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;means a unique account created for You to access our Service or
        parts of our Service.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Affiliate</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;means an entity that controls, is controlled by or is under common
        control with a party, where "control" means ownership of 50% or more of
        the shares, equity interest or other securities entitled to vote for
        election of directors or other managing authority.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Application</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;refers to If I Die, the software program provided by the Company.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Company</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;(referred to as either "the Company", "We", "Us" or "Our" in this
        Agreement) refers to If I Die.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Country</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>&nbsp;refers to: Norway</span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Device</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;means any device that can access the Service such as a computer, a
        cellphone or a digital tablet.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Personal Data</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;is any information that relates to an identified or identifiable
        individual.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Service</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;refers to the Application.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Service Provider</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;means any natural or legal person who processes the data on behalf
        of the Company. It refers to third-party companies or individuals
        employed by the Company to facilitate the Service, to provide the
        Service on behalf of the Company, to perform services related to the
        Service or to assist the Company in analyzing how the Service is used.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>
          Third-party Social Media Service
        </span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;refers to any website or any social network website through which
        a User can log in or create an account to use the Service.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>Usage Data</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;refers to data collected automatically, either generated by the
        use of the Service or from the Service infrastructure itself (for
        example, the duration of a page visit).
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>You</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;means the individual accessing or using the Service, or the
        company, or other legal entity on behalf of which such individual is
        accessing or using the Service, as applicable.
      </span>
    </li>
  </ul>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Collecting and Using Your Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Types of Data Collected
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.25in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      While using Our Service, We may ask You to provide Us with certain
      personally identifiable information that can be used to contact or
      identify You. Personally identifiable information may include, but is not
      limited to:
    </span>
  </p>
  <ul
    type="disc"
    style={{
      marginBottom: "0in",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "start",
      textIndent: 0,
      textDecoration: "none",
      marginTop: "0in",
    }}
  >
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>Email address</span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>First name and last name</span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>Phone number</span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>Usage Data</span>
    </li>
  </ul>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>Usage Data</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Usage Data is collected automatically when using the Service.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Usage Data may include information such as Your Device's Internet Protocol
      address (e.g. IP address), browser type, browser version, the pages of our
      Service that You visit, the time and date of Your visit, the time spent on
      those pages, unique device identifiers and other diagnostic data.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      When You access the Service by or through a mobile device, We may collect
      certain information automatically, including, but not limited to, the type
      of mobile device You use, Your mobile device unique ID, the IP address of
      Your mobile device, Your mobile operating system, the type of mobile
      Internet browser You use, unique device identifiers and other diagnostic
      data.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      We may also collect information that Your browser sends whenever You visit
      our Service or when You access the Service by or through a mobile device.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Information from Third-Party Social Media Services
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.25in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The Company allows You to create an account and log in to use the Service
      through the following Third-party Social Media Services:
    </span>
  </p>
  <ul
    type="disc"
    style={{
      marginBottom: "0in",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "start",
      textIndent: 0,
      textDecoration: "none",
      marginTop: "0in",
    }}
  >
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>Google</span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>Apple</span>
    </li>
  </ul>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      If You decide to register through or otherwise grant us access to a
      Third-Party Social Media Service, We may collect Personal data that is
      already associated with Your Third-Party Social Media Service's account,
      such as Your name, Your email address, Your activities or Your contact
      list associated with that account.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      You may also have the option of sharing additional information with the
      Company through Your Third-Party Social Media Service's account. If You
      choose to provide such information and Personal Data, during registration
      or otherwise, You are giving the Company permission to use, share, and
      store it in a manner consistent with this Privacy Policy.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Use of Your Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.25in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The Company may use Personal Data for the following purposes:
    </span>
  </p>
  <ul
    type="disc"
    style={{
      marginBottom: "0in",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "start",
      textIndent: 0,
      textDecoration: "none",
      marginTop: "0in",
    }}
  >
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>
          To provide and maintain our Service
        </span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        , including to monitor the usage of our Service.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>To manage Your Account:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;to manage Your registration as a user of the Service. The Personal
        Data You provide can give You access to different functionalities of the
        Service that are available to You as a registered user.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>
          For the performance of a contract:
        </span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;the development, compliance and undertaking of the purchase
        contract for the products, items or services You have purchased or of
        any other contract with Us through the Service.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>To contact You:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;To contact You by email, telephone calls, SMS, or other equivalent
        forms of electronic communication, such as a mobile application's push
        notifications regarding updates or informative communications related to
        the functionalities, products or contracted services, including the
        security updates, when necessary or reasonable for their implementation.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>To provide You</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;with news, special offers and general information about other
        goods, services and events which we offer that are similar to those that
        you have already purchased or enquired about unless You have opted not
        to receive such information.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>To manage Your requests:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;To attend and manage Your requests to Us.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>For business transfers:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;We may use Your information to evaluate or conduct a merger,
        divestiture, restructuring, reorganization, dissolution, or other sale
        or transfer of some or all of Our assets, whether as a going concern or
        as part of bankruptcy, liquidation, or similar proceeding, in which
        Personal Data held by Us about our Service users is among the assets
        transferred.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>For other purposes</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        : We may use Your information for other purposes, such as data analysis,
        identifying usage trends, determining the effectiveness of our
        promotional campaigns and to evaluate and improve our Service, products,
        services, marketing and your experience.
      </span>
    </li>
  </ul>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      We may share Your personal information in the following situations:
    </span>
  </p>
  <ul
    type="disc"
    style={{
      marginBottom: "0in",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "start",
      textIndent: 0,
      textDecoration: "none",
      marginTop: "0in",
    }}
  >
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>With Service Providers:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;We may share Your personal information with Service Providers to
        monitor and analyze the use of our Service, to contact You.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>For business transfers:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;We may share or transfer Your personal information in connection
        with, or during negotiations of, any merger, sale of Company assets,
        financing, or acquisition of all or a portion of Our business to another
        company.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>With Affiliates:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;We may share Your information with Our affiliates, in which case
        we will require those affiliates to honor this Privacy Policy.
        Affiliates include Our parent company and any other subsidiaries, joint
        venture partners or other companies that We control or that are under
        common control with Us.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>With business partners:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;We may share Your information with Our business partners to offer
        You certain products, services or promotions.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>With other users:</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        &nbsp;when You share personal information or otherwise interact in the
        public areas with other users, such information may be viewed by all
        users and may be publicly distributed outside. If You interact with
        other users or register through a Third-Party Social Media Service, Your
        contacts on the Third-Party Social Media Service may see Your name,
        profile, pictures and description of Your activity. Similarly, other
        users will be able to view descriptions of Your activity, communicate
        with You and view Your profile.
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <strong>
        <span style={{ fontFamily: "Poppins" }}>With Your consent</span>
      </strong>
      <span style={{ fontFamily: "Poppins" }}>
        : We may disclose Your personal information for any other purpose with
        Your consent.
      </span>
    </li>
  </ul>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Retention of Your Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The Company will retain Your Personal Data only for as long as is
      necessary for the purposes set out in this Privacy Policy. We will retain
      and use Your Personal Data to the extent necessary to comply with our
      legal obligations (for example, if we are required to retain your data to
      comply with applicable laws), resolve disputes, and enforce our legal
      agreements and policies.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The Company will also retain Usage Data for internal analysis purposes.
      Usage Data is generally retained for a shorter period of time, except when
      this data is used to strengthen the security or to improve the
      functionality of Our Service, or We are legally obligated to retain this
      data for longer time periods.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Transfer of Your Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Your information, including Personal Data, is processed at the Company's
      operating offices and in any other places where the parties involved in
      the processing are located. It means that this information may be
      transferred to — and maintained on — computers located outside of Your
      state, province, country or other governmental jurisdiction where the data
      protection laws may differ than those from Your jurisdiction.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Your consent to this Privacy Policy followed by Your submission of such
      information represents Your agreement to that transfer.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The Company will take all steps reasonably necessary to ensure that Your
      data is treated securely and in accordance with this Privacy Policy and no
      transfer of Your Personal Data will take place to an organization or a
      country unless there are adequate controls in place including the security
      of Your data and other personal information.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Delete Your Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      You have the right to delete or request that We assist in deleting the
      Personal Data that We have collected about You.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Our Service may give You the ability to delete certain information about
      You from within the Service.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      You may update, amend, or delete Your information at any time by signing
      in to Your Account, if you have one, and visiting the account settings
      section that allows you to manage Your personal information. You may also
      contact Us to request access to, correct, or delete any personal
      information that You have provided to Us.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Please note, however, that We may need to retain certain information when
      we have a legal obligation or lawful basis to do so.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Disclosure of Your Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Business Transactions
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      If the Company is involved in a merger, acquisition or asset sale, Your
      Personal Data may be transferred. We will provide notice before Your
      Personal Data is transferred and becomes subject to a different Privacy
      Policy.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Law enforcement
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Under certain circumstances, the Company may be required to disclose Your
      Personal Data if required to do so by law or in response to valid requests
      by public authorities (e.g. a court or a government agency).
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Other legal requirements
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.25in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The Company may disclose Your Personal Data in the good faith belief that
      such action is necessary to:
    </span>
  </p>
  <ul
    type="disc"
    style={{
      marginBottom: "0in",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textAlign: "start",
      textIndent: 0,
      textDecoration: "none",
      marginTop: "0in",
    }}
  >
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>
        Comply with a legal obligation
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>
        Protect and defend the rights or property of the Company
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>
        Prevent or investigate possible wrongdoing in connection with the
        Service
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>
        Protect the personal safety of Users of the Service or the public
      </span>
    </li>
    <li
      style={{
        margin: "0in",
        fontSize: 16,
        fontFamily: "Aptos, sans-serif",
        color: "black",
        textAlign: "justify",
        lineHeight: "18.4px",
      }}
    >
      <span style={{ fontFamily: "Poppins" }}>
        Protect against legal liability
      </span>
    </li>
  </ul>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Security of Your Personal Data
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      The security of Your Personal Data is important to Us, but remember that
      no method of transmission over the Internet, or method of electronic
      storage is 100% secure. While We strive to use commercially acceptable
      means to protect Your Personal Data, We cannot guarantee its absolute
      security.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Children's Privacy
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Our Service does not address anyone under the age of 13. We do not
      knowingly collect personally identifiable information from anyone under
      the age of 13. If You are a parent or guardian and You are aware that Your
      child has provided Us with Personal Data, please contact Us. If We become
      aware that We have collected Personal Data from anyone under the age of 13
      without verification of parental consent, We take steps to remove that
      information from Our servers.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      If We need to rely on consent as a legal basis for processing Your
      information and Your country requires consent from a parent, We may
      require Your parent's consent before We collect and use that information.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Links to Other Websites
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      Our Service may contain links to other websites that are not operated by
      Us. If You click on a third party link, You will be directed to that third
      party's site. We strongly advise You to review the Privacy Policy of every
      site You visit.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      We have no control over and assume no responsibility for the content,
      privacy policies or practices of any third party sites or services.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>
        Changes to this Privacy Policy
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      We may update Our Privacy Policy from time to time. We will notify You of
      any changes by posting the new Privacy Policy on this page.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      We will let You know via email and/or a prominent notice on Our Service,
      prior to the change becoming effective and update the "Last updated" date
      at the top of this Privacy Policy.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      You are advised to review this Privacy Policy periodically for any
      changes. Changes to this Privacy Policy are effective when they are posted
      on this page.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins" }}>
        Privacy and Integrity Policy
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins" }}>Introduction</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      This Privacy and Integrity Policy outlines the responsibilities of our
      users in maintaining the accuracy and integrity of their email
      communications, particularly concerning the verification of their
      well-being. This policy aims to prevent wrongful email transmissions in
      the event that users are mistakenly presumed deceased.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins" }}>
        User Responsibility for Well-Being Verification
      </span>
    </strong>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins" }}>Self-Verification</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      Users are solely responsible for verifying their own well-being. This
      includes promptly updating their status in our system to prevent erroneous
      email transmissions that may occur if they are incorrectly presumed
      deceased.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: '"Segoe UI Symbol", sans-serif' }}>⁠</span>
    <strong>
      <span style={{ fontFamily: "Poppins" }}>Regular Updates</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      Users must regularly update their contact information and any relevant
      status indicators to ensure the accuracy of our records. Failure to do so
      may result in the wrongful activation of email protocols designed for
      deceased users.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins" }}>Notification Protocols</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      In the event of a significant change in their well-being status, users
      should immediately notify our support team to update their records. This
      includes any circumstances that might temporarily impair their ability to
      respond or communicate effectively.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>&nbsp;</span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins" }}>Limitations of Liability</span>
    </strong>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: '"Segoe UI Symbol", sans-serif' }}>⁠</span>
    <strong>
      <span style={{ fontFamily: "Poppins" }}>Accuracy of Information</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      Our service relies on the information provided by users. We are not
      responsible for any consequences resulting from inaccurate or outdated
      information provided by the user regarding their well-being.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: '"Segoe UI Symbol", sans-serif' }}>⁠</span>
    <strong>
      <span style={{ fontFamily: "Poppins" }}>User Oversight</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      Users must exercise due diligence in managing their account settings and
      status. The company is not liable for any wrongful email transmissions
      that result from the user's failure to verify their well-being or update
      their status in a timely manner.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins" }}>Implementation and Review</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      This policy is effective as of [29.05.24] and will be reviewed
      periodically to ensure its continued relevance and effectiveness. Users
      will be notified of any significant changes to this policy.
    </span>
  </p>
  <p
    style={{
      margin: "12pt 0in 0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>
      By using our service, users acknowledge and agree to the terms of this
      Privacy and Integrity Policy, affirming their responsibility to maintain
      accurate and current information regarding their well-being.
    </span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins" }}>&nbsp;</span>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textIndent: 0,
      textDecoration: "none",
      textAlign: "justify",
      lineHeight: "18.4px",
    }}
  >
    <strong>
      <span style={{ fontFamily: "Poppins", color: "black" }}>Contact Us</span>
    </strong>
  </p>
  <p
    style={{
      margin: "0in",
      fontSize: "medium",
      fontFamily: "Aptos, sans-serif",
      color: "rgb(0, 0, 0)",
      fontStyle: "normal",
      fontWeight: 400,
      textDecoration: "none",
      textAlign: "justify",
      textIndent: "0.5in",
      lineHeight: "18.4px",
    }}
  >
    <span style={{ fontFamily: "Poppins", color: "black" }}>
      If you have any questions about this Privacy Policy, You can contact
      us:&nbsp;
      <a
        href="mailto:mylastwords.tech@gmail.com"
        style={{ color: "rgb(150, 96, 125)", textDecoration: "underline" }}
      >
        mylastwords.tech@gmail.com
      </a>
    </span>
  </p>
    </div>
  )
}

export default PrivacyPolicy
