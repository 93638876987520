import React, { useEffect, useState } from "react";
import Logo_IFIDI from "../images/Logo_IFIDIE.png";
import { MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { AES } from "crypto-js"; // Import AES encryption from crypto-js
import CryptoJS from "crypto-js";
import GoogleIcon from "../images/g-logo-r.png";
import FacebookIcon from "../images/f-logo-r.png";
const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State to control loading state

  // useEffect(() => {
  //   // Extract token and userID from URL parameters
  //   const params = new URLSearchParams(window.location.search);
  //   const token = params.get("token");
  //   const userID = params.get("pass");
  //   if (token) {
  //     // Check if both token and userID are not null or undefined
  //     if (
  //       token !== null &&
  //       token !== undefined &&
  //       userID !== null &&
  //       userID !== undefined
  //     ) {
  //       // Encrypt userID and store token and encrypted userID in local storage
  //       const encryptedUserId = CryptoJS.AES.encrypt(
  //         userID,
  //         process.env.REACT_APP_LOGIN_K
  //       ).toString();
  //       console.log("stored");
  //       localStorage.setItem("userId", encryptedUserId);
  //       localStorage.setItem("token", token);
  //       // Navigate to the home page or any other route after storing the token
  //       navigate("/home");
  //     }
  //   }
  // }, []);



  useEffect(() => {
    // Extract token and userID from URL parameters
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const userID = params.get("pass");
    const status = params.get("status");
    if(params &&status==="401"){
      Swal.fire({
        title: "Error",
        text: "Invalid email or password",
        icon: "error",
        showConfirmButton: false, // Hide the OK button
        timer: 2000, // Automatically close after 2 seconds
      });
    }
    // Check if both token and userID are not null or undefined
    if (token && userID) {
        // Encrypt userID and store token and encrypted userID in local storage
        const encryptedUserId = CryptoJS.AES.encrypt(
            userID,
            process.env.REACT_APP_LOGIN_K
        ).toString();
   
        localStorage.setItem("userId", encryptedUserId);
        localStorage.setItem("token", token);
        // Navigate to the home page or any other route after storing the token
        navigate("/home");
    } 
}, []);

  useEffect(() => {
    const token = localStorage.getItem("token");


    if (token) {
      navigate("/home");
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const response = await axios.post(
        "https://backend.mylastwords.cloud/api/v1/user/login",
        { email: username, password, isFromWeb: true }
      );
      if (response.data.success) {
     
        const encryptedUserId = CryptoJS.AES.encrypt(
          response?.data?.data?.userID,
          process.env.REACT_APP_LOGIN_K
        ).toString();
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("userId", encryptedUserId); // Save encrypted userId in local storage
        navigate("/home");
      } else {
        // Invalid email or password
        Swal.fire({
          title: "Error",
          text: "Invalid email or password",
          icon: "error",
          showConfirmButton: false, // Hide the OK button
          timer: 2000, // Automatically close after 2 seconds
        });
      }
    } catch (error) {

      Swal.fire({
        title: "Error",
        text: `${error?.response?.data?.message}`,
        icon: "error",
        showConfirmButton: false, // Hide the OK button
        timer: 2000, // Automatically close after 2 seconds
      });
    } finally {
      setLoading(false); // Reset loading state after API response
    }
  };
  const googleAuth = () => {
    try {
      window.open(
        `https://backend.mylastwords.cloud/api/v1/user/auth/google/callback`,
        "_self"
      );
    } catch (error) {

    }
  };

  return (
    <div className="box">
      <div className="logo">
        <img src={Logo_IFIDI} style={{ width: "30%" }} alt="logo" />
      </div>
      <div className="login-form">
        <h2 className="mb-1 pb-1">Login to Continue!</h2>
        <p>
          Unlock your well-being hub for personalized connections and secure
          verifications.
        </p>

        <form onSubmit={handleSubmit} className="form-content">
          <input
            type="email"
            placeholder="Enter your username"
            className="input-form mb-4"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />

          <input
            type="password"
            placeholder="Enter your password"
            className="input-form mb-4"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button
            type="submit"
            className="submit-btn gradient-custom-2"
            disabled={loading}
          >
            {loading ? <div className="loading-spinner" /> : "SIGN IN"}
          </button>
        </form>
        <div className="social-login">
          <h6 className="social-text">or continue with</h6>
          <div className="social-icons">
            {/* <button className="social-button">
              <img src={FacebookIcon} alt="" className="social-button-img" />
            </button> */}
            <button className="social-button" onClick={googleAuth}>
              <img src={GoogleIcon} alt="" className="social-button-img" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
